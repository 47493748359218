import axios from '@axios'

export default {
  namespaced: true,
  state: {},
  getters: {},
  mutations: {},
  actions: {
    fetchCompanies(ctx, queryParams) {
      return new Promise((resolve, reject) => {
        axios
          .get('/companies', { params: queryParams })
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    fetchCompany(ctx, { id }) {
      return new Promise((resolve, reject) => {
        axios
          .get(`/companies/view/${id}`)
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    addCompany(ctx, companyData) {
      return new Promise((resolve, reject) => {
        axios
          .post('/companies/create', companyData)
          .then(response => resolve(response))
          .catch(error => reject(error.response))
      })
    },
    editCompany(ctx, companyData) {
      return new Promise((resolve, reject) => {
        axios
          .post('/companies/update', companyData)
          .then(response => resolve(response))
          .catch(error => {
            reject(error)
          })
      })
    },
    removeCompany(ctx, id) {
      return new Promise((resolve, reject) => {
        axios
          .post('/companies/delete', { id })
          .then(response => resolve(response))
          .catch(error => {
            reject(error)
          })
      })
    },
  },
}
