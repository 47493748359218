import store from '@/store'
import { mdiAccountCheckOutline, mdiAccountOutline, mdiAccountPlusOutline, mdiAccountRemoveOutline } from '@mdi/js'
import { ref, watch } from '@vue/composition-api'

export default function useCompaniesList() {
  const companyListTable = ref([])

  const tableColumns = [
    { text: 'COMPANY NAME', value: 'name' },
    { text: 'MOBILE NUMBER', value: 'mobile_number' },
    { text: 'EMAIL', value: 'email' },
    {
      text: 'ACTIONS',
      value: 'actions',
      align: 'center',
      sortable: false,
    },
  ]

  const searchQuery = ref('')
  const phoneFilter = ref(null)
  const planFilter = ref(null)
  const statusFilter = ref(null)
  const totalCompanyListTable = ref(0)
  const loading = ref(false)
  const options = ref({
    sortBy: ['id'],
    sortDesc: [true],
  })
  const companyTotalLocal = ref([])
  const selectedRows = ref([])

  // fetch data
  const fetchCompanies = () => {
    store
      .dispatch('app-company/fetchCompanies', {
        q: searchQuery.value,
        options: options.value,
        status: statusFilter.value,
        phone: phoneFilter.value,
        plan: planFilter.value,
      })
      .then(response => {
        const { filteredData, total, companyTotal } = response.data.data
        companyListTable.value = filteredData
        totalCompanyListTable.value = total
        companyTotalLocal.value = companyTotal

        // remove loading state
        loading.value = false
      })
      .catch(error => {
        console.log(error)
      })
  }

  watch([searchQuery, phoneFilter, planFilter, statusFilter, options], () => {
    loading.value = true
    selectedRows.value = []
    fetchCompanies()
  })

  // *===============================================---*
  // *--------- UI ---------------------------------------*
  // *===============================================---*

  // As we changes this field to phone number instead of Role; this function will no longer be needed
  // *===============================================---*

  const resolveCompanyRoleVariant = role => {
    if (role === 'subscriber') return 'primary'
    if (role === 'author') return 'warning'
    if (role === 'maintainer') return 'success'
    if (role === 'editor') return 'info'
    if (role === 'admin') return 'error'

    return 'primary'
  }

  const resolveCompanyRoleIcon = role => {
    if (role === 'subscriber') return mdiAccountOutline
    if (role === 'author') return mdiCogOutline
    if (role === 'maintainer') return mdiDatabaseOutline
    if (role === 'editor') return mdiPencilOutline
    if (role === 'admin') return mdiDnsOutline

    return mdiAccountOutline
  }

  const resolveCompanyStatusVariant = status => {
    if (status === 'pending') return 'warning'
    if (status === 'active') return 'success'
    if (status === 'inactive') return 'secondary'

    return 'primary'
  }

  const resolveCompanyTotalIcon = total => {
    if (total === 'Total Companies') return { icon: mdiAccountOutline, color: 'primary' }
    if (total === 'Paid Companies') return { icon: mdiAccountPlusOutline, color: 'error' }
    if (total === 'Active Companies') return { icon: mdiAccountCheckOutline, color: 'success' }
    if (total === 'Pending Companies') return { icon: mdiAccountRemoveOutline, color: 'warning' }

    return { icon: mdiAccountOutline, color: 'primary' }
  }

  return {
    companyListTable,
    tableColumns,
    searchQuery,
    phoneFilter,
    planFilter,
    statusFilter,
    totalCompanyListTable,
    loading,
    options,
    companyTotalLocal,
    selectedRows,
    fetchCompanies,
    resolveCompanyStatusVariant,
    resolveCompanyTotalIcon,
    resolveCompanyRoleVariant,
  }
}
