<template>
  <div id="company-list">
    <!-- app drawer -->
    <company-list-add-new
      v-model="isAddNewCompanySidebarActive"
      :role-options="roleOptions"
      :plan-options="planOptions"
      @refetch-data="fetchCompanies"
    ></company-list-add-new>

    <!-- company total card -->
    <v-row class="mb-5">
      <v-col
        v-for="total in companyTotalLocal"
        :key="total.total"
        cols="12"
        sm="6"
        md="3"
      >
        <v-card>
          <v-card-text class="d-flex align-center justify-space-between pa-4">
            <div>
              <h2 class="font-weight-semibold mb-1">
                {{ total.total }}
              </h2>
              <span>{{ total.title }}</span>
            </div>

            <v-avatar
              :color="resolveCompanyTotalIcon(total.title).color"
              :class="`v-avatar-light-bg ${resolveCompanyTotalIcon(total.title).color}--text`"
            >
              <v-icon
                size="25"
                :color="resolveCompanyTotalIcon(total.title).color"
                class="rounded-0"
              >
                {{ resolveCompanyTotalIcon(total.title).icon }}
              </v-icon>
            </v-avatar>
          </v-card-text>
        </v-card>
      </v-col>
    </v-row>

    <!-- list filters -->
    <v-card>
      <v-card-title> Companies </v-card-title>
      <!-- <v-row class="px-2 ma-0">
        <v-col
          cols="12"
          sm="4"
        >
          <v-select
            v-model="planFilter"
            placeholder="Select Criteria"
            :items="planOptions"
            item-text="title"
            item-value="value"
            outlined
            dense
            clearable
            hide-details
          ></v-select>
        </v-col>

        <v-col
          cols="12"
          sm="4"
        >
        </v-col>
      </v-row> -->

      <v-divider class="mt-4"></v-divider>

      <!-- actions -->
      <v-card-text class="d-flex align-center flex-wrap pb-0">
        <!-- search -->
        <!-- <v-text-field
          v-model="searchQuery"
          placeholder="Search"
          outlined
          hide-details
          dense
          class="company-search me-3 mb-4"
        >
        </v-text-field> -->

        <v-spacer></v-spacer>

        <div class="d-flex align-center flex-wrap">
          <v-btn
            color="primary"
            class="mb-4 me-3"
            @click.stop="isAddNewCompanySidebarActive = !isAddNewCompanySidebarActive"
          >
            <v-icon>{{ icons.mdiPlus }}</v-icon>
            <span>Add New Company</span>
          </v-btn>
          <!-- <v-btn
            color="secondary"
            outlined
            class="mb-4"
          >
            <v-icon
              size="17"
              class="me-1"
            >
              {{ icons.mdiExportVariant }}
            </v-icon>
            <span>Export</span>
          </v-btn> -->
        </div>
      </v-card-text>

      <!-- table -->
      <v-data-table
        v-model="selectedRows"
        :headers="tableColumns"
        :items="companyListTable"
        :options.sync="options"
        :server-items-length="totalCompanyListTable"
        :loading="loading"
      >
        <!-- name -->
        <template #[`item.name`]="{item}">
          <div class="d-flex align-center">
            <div class="d-flex flex-column ms-3">
              <router-link
                :to="{ name : 'apps-company-view', params: { id: item.id } }"
                class="text--primary font-weight-semibold text-truncate cursor-pointer text-decoration-none"
              >
                {{ item.name }}
              </router-link>
            </div>
          </div>
        </template>

        <!-- role -->
        <template #[`item.role`]="{ item }">
          <div class="d-flex align-center">
            <v-avatar
              size="30"
              :color="resolveCompanyRoleVariant(item.role)"
              :class="`v-avatar-light-bg ${resolveCompanyRoleVariant(item.role)}--text me-3`"
            >
              <v-icon
                size="18"
                :color="resolveCompanyRoleVariant(item.role)"
              >
                {{ resolveCompanyRoleIcon(item.role) }}
              </v-icon>
            </v-avatar>
            <span class="text-capitalize">{{ item.role }}</span>
          </div>
        </template>

        <!-- plan -->
        <template #[`item.currentPlan`]="{ item }">
          <span class="text-capitalize font-weight-semibold text--primary">{{ item.currentPlan }}</span>
        </template>

        <!-- status -->
        <template #[`item.status`]="{ item }">
          <v-chip
            small
            :color="resolveCompanyStatusVariant(item.status)"
            :class="`${resolveCompanyStatusVariant(item.status)}--text`"
            class="v-chip-light-bg font-weight-semibold text-capitalize"
          >
            {{ item.status }}
          </v-chip>
        </template>

        <!-- actions -->
        <template #[`item.actions`]="{ item }">
          <v-menu
            bottom
            left
          >
            <template v-slot:activator="{ on, attrs }">
              <v-btn
                icon
                v-bind="attrs"
                v-on="on"
              >
                <v-icon>{{ icons.mdiDotsVertical }}</v-icon>
              </v-btn>
            </template>

            <v-list>
              <v-list-item :to="{ name: 'apps-company-view', params: { id: item.id } }">
                <v-list-item-title>
                  <v-icon
                    size="20"
                    class="me-2"
                  >
                    {{ icons.mdiFileDocumentOutline }}
                  </v-icon>
                  <span>Details</span>
                </v-list-item-title>
              </v-list-item>

              <v-list-item
                link
                @click="handleDelete(item.id)"
              >
                <v-list-item-title>
                  <v-icon
                    size="20"
                    class="me-2"
                  >
                    {{ icons.mdiDeleteOutline }}
                  </v-icon>
                  <span>Delete</span>
                </v-list-item-title>
              </v-list-item>
            </v-list>
          </v-menu>
        </template>
      </v-data-table>
    </v-card>
  </div>
</template>

<script>
// eslint-disable-next-line object-curly-newline
import store from '@/store'
import {
  mdiAccountOutline,
  mdiDeleteOutline,
  mdiDotsVertical,
  mdiExportVariant,
  mdiFileDocumentOutline,
  mdiPlus,
  mdiSquareEditOutline,
} from '@mdi/js'
import { onUnmounted, ref } from '@vue/composition-api'

// sidebar
import { avatarText } from '@core/utils/filter'
import Swal from 'sweetalert2'
import companyStoreModule from '../companyStoreModule'
import CompanyListAddNew from './CompanyListAddNew.vue'
import useCompaniesList from './useCompaniesList'

export default {
  components: {
    CompanyListAddNew,
  },
  setup(props, { emit }) {
    const COMPANY_APP_STORE_MODULE_NAME = 'app-company'

    // Register module
    if (!store.hasModule(COMPANY_APP_STORE_MODULE_NAME)) {
      store.registerModule(COMPANY_APP_STORE_MODULE_NAME, companyStoreModule)
    }

    // UnRegister on leave
    onUnmounted(() => {
      if (store.hasModule(COMPANY_APP_STORE_MODULE_NAME)) store.unregisterModule(COMPANY_APP_STORE_MODULE_NAME)
    })

    // Delete a company
    const handleDelete = id => {
      Swal.fire({
        title: 'Are you sure?',
        text: "You won't be able to revert this!",
        icon: 'warning',
        showCancelButton: true,
        confirmButtonColor: '#3085d6',
        cancelButtonColor: '#d33',
        confirmButtonText: 'Yes, delete it!',
      })
        .then(result => {
          if (result.isConfirmed) {
            store.dispatch('app-company/removeCompany', id).then(() => {})
            Swal.fire('Deleted!', 'entry has been deleted.', 'success')
            companyListTable.value = companyListTable.value.filter(item => item.id !== id)
          }
        })
        .catch(error => {
          console.log(error)
        })
    }

    const {
      companyListTable,
      tableColumns,
      searchQuery,
      roleFilter,
      planFilter,
      statusFilter,
      totalCompanyListTable,
      loading,
      options,
      companyTotalLocal,
      selectedRows,

      fetchCompanies,
      resolveCompanyRoleVariant,
      resolveCompanyRoleIcon,
      resolveCompanyStatusVariant,
      resolveCompanyTotalIcon,
    } = useCompaniesList()

    const isAddNewCompanySidebarActive = ref(false)

    const roleOptions = [
      { title: 'Admin', value: 'admin' },
      { title: 'Author', value: 'author' },
      { title: 'Editor', value: 'editor' },
      { title: 'Maintainer', value: 'maintainer' },
      { title: 'Subscriber', value: 'subscriber' },
    ]

    const planOptions = [{ title: 'Company Name', value: 'company' }]

    return {
      companyListTable,
      tableColumns,
      searchQuery,
      roleFilter,
      planFilter,
      statusFilter,
      totalCompanyListTable,
      roleOptions,
      planOptions,
      loading,
      options,
      companyTotalLocal,
      isAddNewCompanySidebarActive,
      selectedRows,
      avatarText,
      resolveCompanyRoleVariant,
      resolveCompanyRoleIcon,
      resolveCompanyStatusVariant,
      resolveCompanyTotalIcon,
      fetchCompanies,
      handleDelete,

      // icons
      icons: {
        mdiSquareEditOutline,
        mdiFileDocumentOutline,
        mdiDotsVertical,
        mdiDeleteOutline,
        mdiPlus,
        mdiExportVariant,
        mdiAccountOutline,
      },
    }
  },
}
</script>

<style lang="scss">
@import '@core/preset/preset/apps/user.scss';
</style>
