<template>
  <v-navigation-drawer
    :value="isAddNewCompanySidebarActive"
    :permanent="isAddNewCompanySidebarActive"
    temporary
    touchless
    :right="!$vuetify.rtl"
    :width="$vuetify.breakpoint.smAndUp ? 450 : '100%'"
    app
    @input="val => $emit('update:is-add-new-company-sidebar-active', val)"
  >
    <v-card height="100%">
      <div class="drawer-header d-flex align-center mb-4">
        <span class="font-weight-semibold text-base text--primary">Add New Company</span>
        <v-spacer></v-spacer>
        <v-btn icon small @click="$emit('update:is-add-new-company-sidebar-active', false)">
          <v-icon size="22">
            {{ icons.mdiClose }}
          </v-icon>
        </v-btn>
      </div>

      <v-card-text class="mt-5">
        <v-form ref="form" v-model="valid" @submit.prevent="onSubmit" class="multi-col-validation">
          <v-row>
            <v-col cols="12" sm="6">
              <v-text-field
                v-model="companyData.name"
                :rules="[validators.required]"
                outlined
                dense
                label="Company Name"
              ></v-text-field>
            </v-col>

            <v-col cols="12" sm="6">
              <v-text-field
                v-model="companyData.email"
                :rules="[validators.required, validators.emailValidator]"
                outlined
                dense
                label="Email"
              ></v-text-field>
            </v-col>

            <v-col cols="12" sm="6">
              <v-text-field
                v-model="companyData.mobile_number"
                :rules="[validators.required, validators.integerValidator]"
                type="number"
                hide-spin-buttons
                outlined
                dense
                label="Mobile Number"
              ></v-text-field>
            </v-col>

            <v-col cols="12" sm="6">
              <v-text-field
                v-model="companyData.emergency_number"
                :rules="[validators.required, validators.integerValidator]"
                type="number"
                hide-spin-buttons
                outlined
                dense
                label="Emergency Number"
              ></v-text-field>
            </v-col>
            <v-col cols="12" sm="6">
              <span class="font-weight-medium me-2">Duration:</span>
              <p></p>
              <v-text-field
                v-model="companyData.from"
                outlined
                dense
                type="date"
                label="From"
                @input="()=>{companyData.to = ''}"
                :min="new Date().toISOString().substr(0, 10)"
              ></v-text-field>
            </v-col>

            <v-col
              cols="
                12"
              sm="6"
            >
              <span class="font-weight-medium me-2"></span>
              <p></p>
              <v-text-field
                v-model="companyData.to"
                outlined
                dense
                type="date"
                label="To"
                :min="timeHandler()"
              ></v-text-field>
            </v-col>

            <v-col cols="12" sm="6">
              <v-menu
                ref="menu"
                v-model="menu2"
                :close-on-content-click="false"
                :nudge-right="40"
                :return-value.sync="companyData.break_time"
                transition="scale-transition"
                offset-y
                max-width="290px"
                min-width="290px"
              >
                <template v-slot:activator="{ on, attrs }">
                  <v-text-field
                    v-model="companyData.break_time"
                    :rules="[validators.required]"
                    label="Break Time"
                    prepend-icon=""
                    readonly
                    v-bind="attrs"
                    outlined
                    dense
                    v-on="on"
                  ></v-text-field>
                </template>

                <v-time-picker
                  v-if="menu2"
                  v-model="time"
                  full-width
                  @click:minute="$refs.menu.save(time)"
                ></v-time-picker>
              </v-menu>
            </v-col>

            <v-col cols="12" sm="6">
              <v-text-field
                v-model="companyData.delivery_factor"
                :rules="[validators.required]"
                outlined
                dense
                label="delivery Factor"
              ></v-text-field>
            </v-col>

            <v-col cols="12">
              <v-select
                v-model="companyData.delivery_days"
                :items="deliveryDays"
                chips
                label="Delivery Days"
                multiple
                outlined
                dense
                type="number"
                hide-spin-buttons
              >
              </v-select>
            </v-col>


            <v-col cols="12">
              <span class="font-weight-medium me-2 p-2">Address:</span>
            </v-col>

            <v-col cols="12" sm="6">
              <v-text-field
                v-model="companyData.latitude"
                :rules="[validators.required]"
                type="number"
                hide-spin-buttons
                outlined
                dense
                label="Latitude"
              ></v-text-field>
            </v-col>

            <v-col cols="12" sm="6">
              <v-text-field
                v-model="companyData.longitude"
                :rules="[validators.required]"
                type="number"
                hide-spin-buttons
                outlined
                dense
                label="Longitude"
              ></v-text-field>
            </v-col>

            <v-col cols="12" sm="6">
              <v-text-field
                v-model="companyData.street_name"
                :rules="[validators.required]"
                outlined
                dense
                label="Street Name"
              ></v-text-field>
            </v-col>

            <v-col cols="12" sm="6">
              <v-text-field
                v-model="companyData.building_number"
                :rules="[validators.required]"
                type="number"
                hide-spin-buttons
                outlined
                dense
                label="Building Number"
              ></v-text-field>
            </v-col>

            <v-col cols="12" class="d-flex justify-center mt-3">
              <v-btn color="primary" class="me-3" type="submit"> submit </v-btn>

              <v-btn
                outlined
                color="secondary"
                @click.prevent="$emit('update:is-add-new-company-sidebar-active', false);resetForm()"
              >
                Discard
              </v-btn>
            </v-col>
          </v-row>
        </v-form>
      </v-card-text>
    </v-card>
  </v-navigation-drawer>
</template>

<script>
import countries from '@/@fake-db/data/other/countries'
import store from '@/store'
import { emailValidator, integerValidator, required } from '@core/utils/validation'
import { mdiCalendar, mdiClose } from '@mdi/js'
import { ref } from '@vue/composition-api'
import Swal from 'sweetalert2'
import moment from 'moment'

export default {
  model: {
    prop: 'isAddNewCompanySidebarActive',
    event: 'update:is-add-new-company-sidebar-active',
  },
  props: {
    isAddNewCompanySidebarActive: {
      type: Boolean,
      required: true,
    },
    roleOptions: {
      type: Array,
      required: true,
    },
    planOptions: {
      type: Array,
      required: true,
    },
  },
  data() {
    return {
      time: null,
      menu2: false,
      modal2: false,

      // don't change this ordered array
      deliveryDays: ['Sun', 'Mon', 'Tue', 'Wed', 'Thu', 'Sat', 'Fri'],
    }
  },

  setup(props, { emit }) {
    const deliveryDaysKeys = {
      Sun: 1,
      Mon: 2,
      Tue: 3,
      Wed: 4,
      Thu: 5,
      Sat: 6,
      Fri: 7,
    }

    const valid = ref(false)
    const form = ref(null)

    const validate = () => {
      form.value.validate()
    }

    const resetForm = () => {
      form.value.reset()
    }

    const timeHandler = () => {
      if (companyData.value?.from) {
        return moment(companyData.value.from).add('1','day').format('YYYY-MM-DD')
      } else {
        return moment().add('1','day').format('YYYY-MM-DD');
      }
    }

    const blankCompanyData = {
      name: '',
      email: '',
      mobile_number: '',
      emergency_number: '',
      from: '',
      to: '',
      break_time: '',
      delivery_days: [],
      delivery_factor: '',
      longitude: '',
      latitude: '',
      street_name: '',
      building_number: '',
    }

    const companyData = ref(JSON.parse(JSON.stringify(blankCompanyData)))

    const resetcompanyData = () => {
      companyData.value = JSON.parse(JSON.stringify(blankCompanyData))
      resetForm()
      emit('refetch-data')
    }

    const onSubmit = () => {
      if (valid.value) {
        companyData.value.from = new Date(companyData.value.from)
        companyData.value.to = new Date(companyData.value.to)
        companyData.value.delivery_days = companyData.value.delivery_days.map(day => deliveryDaysKeys[day])
        companyData.value.city_id = 1
        store
          .dispatch('app-company/addCompany', companyData.value)
          .then(() => {
            emit('update:is-add-new-company-sidebar-active', false)
            resetcompanyData()
          })
          .catch(error => {
            Object.entries(error.data.message).forEach(err => {
              Swal.fire({
                icon: 'error',
                title: `${err[0]}`,
                text: `${err[1][0]}`,
              })
            })
          })
      } else {
        validate()
      }
    }

    return {
      form,
      countries,
      companyData,
      valid,
      timeHandler,
      resetcompanyData,
      onSubmit,
      validate,
      resetForm,

      // validation
      validators: { required, emailValidator, integerValidator },
      icons: {
        mdiClose,
        mdiCalendar,
      },
    }
  },
}
</script>
