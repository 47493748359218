import { VAvatar } from 'vuetify/lib/components/VAvatar';
import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCard } from 'vuetify/lib/components/VCard';
import { VCardText } from 'vuetify/lib/components/VCard';
import { VCardTitle } from 'vuetify/lib/components/VCard';
import { VChip } from 'vuetify/lib/components/VChip';
import { VCol } from 'vuetify/lib/components/VGrid';
import { VDataTable } from 'vuetify/lib/components/VDataTable';
import { VDivider } from 'vuetify/lib/components/VDivider';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VList } from 'vuetify/lib/components/VList';
import { VListItem } from 'vuetify/lib/components/VList';
import { VListItemTitle } from 'vuetify/lib/components/VList';
import { VMenu } from 'vuetify/lib/components/VMenu';
import { VRow } from 'vuetify/lib/components/VGrid';
import { VSpacer } from 'vuetify/lib/components/VGrid';

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{attrs:{"id":"company-list"}},[_c('company-list-add-new',{attrs:{"role-options":_vm.roleOptions,"plan-options":_vm.planOptions},on:{"refetch-data":_vm.fetchCompanies},model:{value:(_vm.isAddNewCompanySidebarActive),callback:function ($$v) {_vm.isAddNewCompanySidebarActive=$$v},expression:"isAddNewCompanySidebarActive"}}),_c(VRow,{staticClass:"mb-5"},_vm._l((_vm.companyTotalLocal),function(total){return _c(VCol,{key:total.total,attrs:{"cols":"12","sm":"6","md":"3"}},[_c(VCard,[_c(VCardText,{staticClass:"d-flex align-center justify-space-between pa-4"},[_c('div',[_c('h2',{staticClass:"font-weight-semibold mb-1"},[_vm._v(" "+_vm._s(total.total)+" ")]),_c('span',[_vm._v(_vm._s(total.title))])]),_c(VAvatar,{class:("v-avatar-light-bg " + (_vm.resolveCompanyTotalIcon(total.title).color) + "--text"),attrs:{"color":_vm.resolveCompanyTotalIcon(total.title).color}},[_c(VIcon,{staticClass:"rounded-0",attrs:{"size":"25","color":_vm.resolveCompanyTotalIcon(total.title).color}},[_vm._v(" "+_vm._s(_vm.resolveCompanyTotalIcon(total.title).icon)+" ")])],1)],1)],1)],1)}),1),_c(VCard,[_c(VCardTitle,[_vm._v(" Companies ")]),_c(VDivider,{staticClass:"mt-4"}),_c(VCardText,{staticClass:"d-flex align-center flex-wrap pb-0"},[_c(VSpacer),_c('div',{staticClass:"d-flex align-center flex-wrap"},[_c(VBtn,{staticClass:"mb-4 me-3",attrs:{"color":"primary"},on:{"click":function($event){$event.stopPropagation();_vm.isAddNewCompanySidebarActive = !_vm.isAddNewCompanySidebarActive}}},[_c(VIcon,[_vm._v(_vm._s(_vm.icons.mdiPlus))]),_c('span',[_vm._v("Add New Company")])],1)],1)],1),_c(VDataTable,{attrs:{"headers":_vm.tableColumns,"items":_vm.companyListTable,"options":_vm.options,"server-items-length":_vm.totalCompanyListTable,"loading":_vm.loading},on:{"update:options":function($event){_vm.options=$event}},scopedSlots:_vm._u([{key:"item.name",fn:function(ref){
var item = ref.item;
return [_c('div',{staticClass:"d-flex align-center"},[_c('div',{staticClass:"d-flex flex-column ms-3"},[_c('router-link',{staticClass:"text--primary font-weight-semibold text-truncate cursor-pointer text-decoration-none",attrs:{"to":{ name : 'apps-company-view', params: { id: item.id } }}},[_vm._v(" "+_vm._s(item.name)+" ")])],1)])]}},{key:"item.role",fn:function(ref){
var item = ref.item;
return [_c('div',{staticClass:"d-flex align-center"},[_c(VAvatar,{class:("v-avatar-light-bg " + (_vm.resolveCompanyRoleVariant(item.role)) + "--text me-3"),attrs:{"size":"30","color":_vm.resolveCompanyRoleVariant(item.role)}},[_c(VIcon,{attrs:{"size":"18","color":_vm.resolveCompanyRoleVariant(item.role)}},[_vm._v(" "+_vm._s(_vm.resolveCompanyRoleIcon(item.role))+" ")])],1),_c('span',{staticClass:"text-capitalize"},[_vm._v(_vm._s(item.role))])],1)]}},{key:"item.currentPlan",fn:function(ref){
var item = ref.item;
return [_c('span',{staticClass:"text-capitalize font-weight-semibold text--primary"},[_vm._v(_vm._s(item.currentPlan))])]}},{key:"item.status",fn:function(ref){
var item = ref.item;
return [_c(VChip,{staticClass:"v-chip-light-bg font-weight-semibold text-capitalize",class:((_vm.resolveCompanyStatusVariant(item.status)) + "--text"),attrs:{"small":"","color":_vm.resolveCompanyStatusVariant(item.status)}},[_vm._v(" "+_vm._s(item.status)+" ")])]}},{key:"item.actions",fn:function(ref){
var item = ref.item;
return [_c(VMenu,{attrs:{"bottom":"","left":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c(VBtn,_vm._g(_vm._b({attrs:{"icon":""}},'v-btn',attrs,false),on),[_c(VIcon,[_vm._v(_vm._s(_vm.icons.mdiDotsVertical))])],1)]}}],null,true)},[_c(VList,[_c(VListItem,{attrs:{"to":{ name: 'apps-company-view', params: { id: item.id } }}},[_c(VListItemTitle,[_c(VIcon,{staticClass:"me-2",attrs:{"size":"20"}},[_vm._v(" "+_vm._s(_vm.icons.mdiFileDocumentOutline)+" ")]),_c('span',[_vm._v("Details")])],1)],1),_c(VListItem,{attrs:{"link":""},on:{"click":function($event){return _vm.handleDelete(item.id)}}},[_c(VListItemTitle,[_c(VIcon,{staticClass:"me-2",attrs:{"size":"20"}},[_vm._v(" "+_vm._s(_vm.icons.mdiDeleteOutline)+" ")]),_c('span',[_vm._v("Delete")])],1)],1)],1)],1)]}}],null,true),model:{value:(_vm.selectedRows),callback:function ($$v) {_vm.selectedRows=$$v},expression:"selectedRows"}})],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }